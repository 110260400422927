import { graphql } from "gatsby";
import React from "react";
import SEO from "../components/Seo";
import LogoWordmark from "../components/NavigationBar/assets/logo-wordmark.svg";
import Section from "../containers/Section";
import SocialLinks from "../components/LinkInBio/SocialLinks";
import ResourcesLinks from "../components/LinkInBio/ResourcesLinks";
import LatestBlogs from "../components/LinkInBio/LatestBlogs";
import { IconType } from "../components/Icon";
import { SOCIAL_LINKS } from "../constants/destinations";

interface InstagramProps {
  data: any;
}

const Instagram: React.FC<InstagramProps> = ({ data }) => {
  const latestBlogPost = data?.allContentfulPublication?.nodes;
  const resourcesLinks =
    data?.allContentfulLinkInBio?.nodes[0]?.instagramResourcesLinks;

  const socialLinks = [
    {
      link: SOCIAL_LINKS.Instagram,
      icon: IconType.instagram
    },
    {
      link: SOCIAL_LINKS.TikTok,
      icon: IconType.tiktok
    },
    {
      link: SOCIAL_LINKS.YouTube,
      icon: IconType.youtube
    },
    {
      link: SOCIAL_LINKS.LinkedIn,
      icon: IconType.linkedin
    },
    {
      link: SOCIAL_LINKS.Facebook,
      icon: IconType.facebook
    },
    {
      link: SOCIAL_LINKS.Twitter,
      icon: IconType.twitter
    }
  ];

  return (
    <main className="bg-gradient-to-b from-white via-white to-indigo-3-light relative min-h-screen overflow-hidden">
      <Section className="flex flex-col items-center py-16">
        <SEO title={""} description={""} robots="noindex nofollow" />
        <a href="/">
          <LogoWordmark />
        </a>

        <SocialLinks socialLinks={socialLinks} />
        <ResourcesLinks resourcesLinks={resourcesLinks} />
        <LatestBlogs latestBlogPost={latestBlogPost} />
      </Section>
    </main>
  );
};

export default Instagram;

export const data = graphql`
  query {
    allContentfulPublication(
      limit: 3
      sort: { order: DESC, fields: publishDate }
      filter: { node_locale: { eq: "en-US" }, title: {ne: null} }
    ) {
      nodes {
        slug
        title
        bannerImage {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
    }
    allContentfulLinkInBio(filter: { node_locale: { eq: "en-US" } }) {
      nodes {
        instagramResourcesLinks {
          slug
          text
        }
      }
    }
  }
`;
