// URL
export enum URL {
  Login = "https://app.perpetua.io/login",
  SignUp = "https://app.perpetua.io/signup",
  Agency = "/agencies",
  Video = "https://app.perpetua.io/brands/add/video/?openvideocreator=true",
  Demo = "/demo/",
  BenchMarker = "https://perpetua.io/amazon-advertising-benchmark-tool/"
}

export enum Intercom {
  Open = "intercom"
}

export enum Hubspot {
  portalId = "4633075",
  demoFormId_DE = "8e7495c4-a463-4f28-9854-65ee6e674197",
  demoFormId_EN = "551213bb-d30e-47ad-b8cc-c6f40838cf60",
  BIformId = "375f9b91-33fa-49a2-ae23-0b7c80681485",
  resourceDefaultFormId = "3157dcfe-fa7f-4ef7-8b64-ff946b27826c",
  affiliateFormId = "9577c00e-04f0-4499-8309-fec34e212187",
  AMAZON_ACCELERATOR_PROGRAM_FORM_ID = "f3e84954-5889-4091-99f5-9938d70f872a",
}

export enum SOCIAL_LINKS {
  Instagram = "https://www.instagram.com/perpetua.io/",
  TikTok = "https://www.tiktok.com/@perpetua.io",
  YouTube = "https://www.youtube.com/channel/UCVPWE5c8kRN6QOcp8ntIbTw",
  LinkedIn = "https://www.linkedin.com/company/perpetua-labs/",
  Facebook = "https://www.facebook.com/perpetualabs/",
  Twitter = "https://twitter.com/PerpetuaLabs"
}

export enum BIZZBAO_EVENTS{
  ACTIVE = "459435"
}
